.reportcard-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.reportcard {
    background: #fff;
    border-right: 1px solid #ebeef0;
    cursor: pointer;
    width: 25%;
    float: left;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
}
.reportcard .reportcard-link {
    display: block;
    text-decoration: none;
    width: 100%;
    border-bottom: 1px solid #ebeef0;
}
.pb {
    padding-bottom: 10px!important;
}
.reportcard ul.unstyled>li {
    list-style: none;
}

.reportcard .reportcard-banner {
    height: 40px;
    position: relative;
}
.tc {
    text-align: center!important;
}
.reportcard .reportcard-icon {
    margin-top: 20px;
    color: #000;
    font-size: 24px;
}
.sales_reports:hover .reportcard-name {
    background: #eff9fd;
    color: #008cd3;
}
.reportcard ul.unstyled>li {
    list-style: none;
}
.reportcard .reportcard-name {
    color: #31373d;
    margin: 13px 0 0;
    font-size: 14px;
}
.sales_reports:hover, .sales_reports:hover .reportcard-icon {
    background: #eff9fd;
    color: #08f;
}