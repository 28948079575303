
:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
}

body {
    /* background: #007bff; */
    background: -webkit-gradient(linear, left top, right top, from(#0062E6), to(#33AEFF));
    background: -webkit-linear-gradient(left, #0062E6, #33AEFF);
    background: linear-gradient(to right, #0062E6, #33AEFF);
    background: url(/imgages/page-bg.png) no-repeat 50%;
    background-size: cover;
    min-height: 100vh;
}

.card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 2rem;
}

.form-signin {
    width: 100%;
}

.form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;

}

.dangnhap {
    background-image: -webkit-linear-gradient(315deg, #bd2a36, #9b4599, #214181);
    background-image: linear-gradient(135deg, #bd2a36, #9b4599, #214181);
    color: #fff;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input {
    height: auto;
    /* border-radius: 2rem; */
}

.form-label-group>input,
.form-label-group>label {
    padding: .75rem 1.5rem;
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(.75rem + .75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
    padding-top: calc(.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

.btn-google {
    color: white;
    background-color: #ea4335;
}

.btn-facebook {
    color: white;
    background-color: #3b5998;
}

.mt-20 {
    margin-top: 20px;
}


/* table,
thead,
tr,
tbody,
th,
td {
  text-align: center;
} */

/* .table td {
  text-align: center;
} */
.month-picker {
  position: relative; }
  .month-picker > .rmp-container {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 100;
    top: 1px;
    left: -10000px;
    -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
    @media screen and (max-width: 767px) {
      .month-picker > .rmp-container {
        position: fixed;
        top: 0;
        left: -10000px;
        width: 100%;
        height: 100%;
        -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
        transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
    .month-picker > .rmp-container.rmp-table {
      display: table; }
    .month-picker > .rmp-container.show {
      left: 0;
      -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      .month-picker > .rmp-container.show .rmp-overlay {
        left: 0; }
    .month-picker > .rmp-container .rmp-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9;
      top: 0;
      left: -10000px;
      opacity: 1;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      will-change: opacity;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
      transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-overlay {
          background-color: rgba(0, 0, 0, 0.25); } }
    .month-picker > .rmp-container .rmp-cell {
      display: table-cell;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 100%; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-cell {
          vertical-align: bottom; } }
    .month-picker > .rmp-container .rmp-popup {
      position: absolute;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
      margin: 0 auto;
      z-index: 10;
      font-size: 1.2rem;
      opacity: 0;
      border-radius: 3px;
      padding: 0.4rem;
      box-sizing: content-box; }
      @media screen and (max-width: 767px) {
        .month-picker > .rmp-container .rmp-popup {
          box-sizing: border-box;
          bottom: 0;
          width: 100%;
          max-width: 100%;
          height: 14.4rem;
          -webkit-transform: translate3d(0, 14.4rem, 0);
          transform: translate3d(0, 14.4rem, 0); }
          .month-picker > .rmp-container .rmp-popup.range {
            height: 28rem;
            -webkit-transform: translate3d(0, 28rem, 0);
            transform: translate3d(0, 28rem, 0); }
            .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
              margin-top: 0.4rem; }
              .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
                margin-top: 0; } }
      @media screen and (min-width: 768px) {
        .month-picker > .rmp-container .rmp-popup {
          -webkit-transform: translate3d(0, -64px, 0);
          transform: translate3d(0, -64px, 0);
          top: 0;
          width: 20rem; }
          .month-picker > .rmp-container .rmp-popup.range {
            width: 40.6rem;
            padding: 0.6rem; } }
      .month-picker > .rmp-container .rmp-popup.show {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0); }
      .month-picker > .rmp-container .rmp-popup:after {
        content: ' ';
        clear: both;
        display: table; }
      .month-picker > .rmp-container .rmp-popup .rmp-pad {
        position: relative; }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup .rmp-pad {
            box-sizing: border-box;
            float: left;
            width: 20rem; }
            .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
              float: right; } }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
          display: block;
          font-size: 1.4rem;
          text-align: center;
          line-height: 3.4rem; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
            font-weight: normal;
            margin-right: 0.5em; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
          font-style: normal;
          text-align: center;
          width: 3.4rem;
          height: 3.4rem;
          line-height: 3.4rem;
          position: absolute;
          top: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
            left: 0; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
            right: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          list-style-type: none;
          margin: 0;
          padding: 0; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad ul {
          display: block;
          width: 100%; }
          .month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
            content: ' ';
            clear: both;
            display: table; }
        .month-picker > .rmp-container .rmp-popup .rmp-pad li {
          display: block;
          float: left;
          text-align: center;
          line-height: 3.4rem;
          font-size: 1.15rem;
          border-radius: 2px;
          text-overflow: ellipsis;
          white-space: nowrap;
          *white-space: nowrap;
          overflow: hidden;
          -webkit-font-smoothing: antialiased;
          -webkit-text-stroke-width: 0.2px;
          -moz-osx-font-smoothing: grayscale;
          -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
          transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
          @media screen and (max-width: 767px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 25%; } }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup .rmp-pad li {
              width: 33.3333333333%; } }
      .month-picker > .rmp-container .rmp-popup.light {
        color: #666;
        background-color: rgba(255, 255, 255, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border-top: 1px solid #ccc;
            box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              border-top: 1px solid rgba(204, 204, 204, 0.5); }
              .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
                border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.light {
            border: 1px solid #ccc;
            box-shadow: 0 1px 5px #ddd; }
            .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
              background-color: rgba(238, 238, 238, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
          cursor: pointer; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 227, 160, 0.59); } }
          .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
            background-color: #d3d3d3; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
          background-color: rgba(31, 42, 58, 0.73);
          color: white;
          cursor: default; }
        .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover {
          background-color: transparent;
          color: #bbb;
          cursor: default; }
      .month-picker > .rmp-container .rmp-popup.dark {
        color: #fff;
        background-color: rgba(50, 50, 50, 0.96); }
        @media screen and (max-width: 767px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            border-top: 1px solid rgba(113, 113, 113, 0.41); }
            .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
              border-top: 0; } }
        @media screen and (min-width: 768px) {
          .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
            background-color: rgba(70, 70, 70, 0.9); } }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
          cursor: pointer; }
          @media screen and (min-width: 768px) {
            .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
              background-color: rgba(255, 210, 96, 0.33); } }
          .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
            background-color: #262828; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
          background-color: rgba(189, 211, 242, 0.7);
          color: #303030;
          cursor: default; }
        .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover {
          background-color: transparent;
          color: #717171;
          cursor: default; }

.reportcard-container {
    display: flex;
    flex-wrap: wrap;
}
.reportcard {
    background: #fff;
    border-right: 1px solid #ebeef0;
    cursor: pointer;
    width: 25%;
    float: left;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}
.reportcard .reportcard-link {
    display: block;
    text-decoration: none;
    width: 100%;
    border-bottom: 1px solid #ebeef0;
}
.pb {
    padding-bottom: 10px!important;
}
.reportcard ul.unstyled>li {
    list-style: none;
}

.reportcard .reportcard-banner {
    height: 40px;
    position: relative;
}
.tc {
    text-align: center!important;
}
.reportcard .reportcard-icon {
    margin-top: 20px;
    color: #000;
    font-size: 24px;
}
.sales_reports:hover .reportcard-name {
    background: #eff9fd;
    color: #008cd3;
}
.reportcard ul.unstyled>li {
    list-style: none;
}
.reportcard .reportcard-name {
    color: #31373d;
    margin: 13px 0 0;
    font-size: 14px;
}
.sales_reports:hover, .sales_reports:hover .reportcard-icon {
    background: #eff9fd;
    color: #08f;
}
